import React, { MouseEvent } from 'react';
import { tw } from '@didomi/ui-foundation';
import { DidomiButton, DidomiPromotionalPush } from '@didomi/ui-atoms-react';
import { useEnvironment } from '@didomi/utility-react';

type PromoteBookDemoProps = {
  float?: boolean;
  className?: string;
  disabled?: boolean;
  isSmallLayout?: boolean;
  onActionClicked?: (event: MouseEvent<HTMLDidomiButtonElement, globalThis.MouseEvent>) => void;
};

/**
 * Renders promotional push that let's you book a demo
 *
 * @component
 * @param {PromoteBookDemoProps} props
 * @example
 * return (
 *   <PromoteBookDemo
 *      float={false}
 *   />
 * )
 */
export const PromoteBookDemo = ({ className = '', float, isSmallLayout = false, disabled, onActionClicked }: PromoteBookDemoProps) => {
  const { assetsBaseUrl } = useEnvironment();

  return (
    <DidomiPromotionalPush
      variant="light"
      borderStyle={float ? 'plain' : 'normal'}
      alignment={float ? 'center' : 'left'}
      data-testid="book-a-demo-push"
      vertical={isSmallLayout}
      className={tw(className, {
        'absolute bottom-0 left-0 w-full': float,
        flex: !float,
      })}
    >
      <img
        className={tw('rounded-full', {
          'h-[36px] w-[36px]': float,
          'h-[80px] min-w-[80px]': !float,
        })}
        slot="img"
        src={`${assetsBaseUrl}/global/Man-expert.png`}
        alt=""
      />

      <div
        slot="title"
        className={tw('text-left flex', {
          'flex-col': isSmallLayout || !float,
          'items-center': isSmallLayout,
          '-mb-xxxs': float,
        })}
      >
        <div className="font-serif text-[20px]">Interested?</div>
        <div
          className={tw('font-normal', {
            'ml-xxs': float,
            'mt-xxxs': !float,
          })}
        >
          Get in touch with our team to know more about the CMP.
        </div>
      </div>

      <DidomiButton
        slot="cta"
        fullWidth={isSmallLayout}
        data-tracking="self-service-cmp-talk-to-expert"
        size={float && !isSmallLayout ? 'small' : 'large'}
        variant="secondary-light"
        onClick={onActionClicked}
        disabled={disabled}
        data-skeleton
      >
        Book a demo
      </DidomiButton>
    </DidomiPromotionalPush>
  );
};
