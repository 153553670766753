import { useAuthToken, useActiveOrganization } from '@didomi/utility-react';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';
import { OrganizationProperty } from '../types/organizationProperty';

type useOrganizationPropertyProps = {
  enabled?: boolean;
};

/**
 * Fetches the organization properties that will return the
 * id that would later be used to retrieve the compliance report
 * We can safely assume a self-service organization we'll only have one property at this point
 * */
export const useOrganizationProperty = ({ enabled }: useOrganizationPropertyProps = { enabled: true }) => {
  const token = useAuthToken();
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchOrganizationProperties = async () => {
    const response = await axiosWithInterceptors.get<{ data: OrganizationProperty[] }>(
      `${CONFIG.environmentConfig.apiBaseUrl}properties?organization_id=${organizationId}&$sort[created_at]=-1`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    const paginatedResponse = response.data;
    /* istanbul ignore next */
    return paginatedResponse?.data?.length ? paginatedResponse.data[0] : null;
  };

  return useQuery<OrganizationProperty, AxiosError<{ message: string }>>(['organization', organizationId, 'property'], fetchOrganizationProperties, {
    enabled: enabled,
  });
};
