import React from 'react';
import { tw } from '@didomi/ui-foundation';
import { PreviewColor } from '@types';

type PreviewColorPickerButtonProps = {
  color: PreviewColor;
  active: boolean;
  onClick?: (event: React.MouseEvent) => void;
  className?: string;
};

/**
 * Component for the selection of a preview color
 *
 * @component
 * @param {PreviewColorPickerButtonProps} props
 * @example
 * return (
 *   <PreviewColorButton color="gray" active={true} onClick={() => onColorChanged('gray')} />
 * )
 */
const PreviewColorButton = ({ className = '', active, color, onClick }: PreviewColorPickerButtonProps) => {
  return (
    <div
      className={tw('h-8 w-8', 'flex items-center justify-center', `border border-solid border-1 border-white rounded-full hover:border-preview-${color}`, className, {
        [`border-preview-${color}`]: active,
      })}
    >
      <button
        data-tracking="self-service-preview-notice-colors"
        title={`${color} theme`}
        onClick={onClick}
        className={tw('w-4 h-4 outline-none! border-0', `rounded-full bg-preview-${color}`, { 'h-6! w-6!': active })}
      ></button>
    </div>
  );
};

type PreviewColorPickerProps = {
  selectedColor: PreviewColor;
  onColorChanged?: (color: PreviewColor) => void;
  className?: string;
};

/**
 * Component for the selection of the preview color theme
 * It allows you to switch colors by clicking on the available color
 *
 * @component
 * @param {PreviewColorPickerProps} props
 * @example
 * const [color, setColor] = useState('blue')
 * return (
 *   <ReportCriteriaCard
 *       color={c}
 *      onColorChanged={c => setColor(c)}
 *   />
 * )
 */
export const PreviewColorPicker = ({ className = '', selectedColor, onColorChanged }: PreviewColorPickerProps) => {
  return (
    <div className={'flex justify-center lg:(flex-col! justify-start!)  ' + className}>
      <PreviewColorButton color="gray" active={selectedColor === 'gray'} onClick={() => onColorChanged('gray')} />
      <PreviewColorButton className="ml-xs lg:(mt-xs! ml-0!)" color="violet" active={selectedColor === 'violet'} onClick={() => onColorChanged('violet')} />
      <PreviewColorButton className="ml-xs lg:(mt-xs! ml-0!)" color="mustard" active={selectedColor === 'mustard'} onClick={() => onColorChanged('mustard')} />
      <PreviewColorButton className="ml-xs lg:(mt-xs! ml-0!)" color="salmon" active={selectedColor === 'salmon'} onClick={() => onColorChanged('salmon')} />
    </div>
  );
};
