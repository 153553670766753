import { useComplianceReport } from '@hooks';

type useShareURLProps = {
  enabled?: boolean;
};

/**
 * Builds the compliance report share URL
 *
 * Note: the compliance report URL depends on the results of the compliance report status, the organization property and the report itself
 * All are handled here for you but take into account that the loading of those will impact the loading for the URL
 * */
export const useShareURL = ({ enabled }: useShareURLProps = { enabled: true }) => {
  const { data: complianceReport, isLoading: loadingComplianceReport } = useComplianceReport({ enabled });

  return {
    data: complianceReport
      ? `${window.location.origin}/share/compliance-report?property_id=${complianceReport.property.id}&period_start=${complianceReport.period_start}&period_end=${complianceReport.period_end}&token=${complianceReport.share_token}`
      : '',
    isLoading: loadingComplianceReport,
  };
};
