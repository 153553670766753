import React, { useRef, useState, useEffect } from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { DidomiSkeleton, DidomiTabHeaders, DidomiTabHeading, DidomiTabHeader } from '@didomi/ui-atoms-react';
import { tw } from '@didomi/ui-foundation';
import { useGTM } from '@didomi/utility-react';
import { PreviewColorPicker } from '@components';
import { PreviewColor, SDKPreviewSection } from '@types';

/**
 * Displays the notice preview by using an iframe that renders the preview inside
 *
 * We send messages to the iframe to update the color theme and change the view
 * The iframe sends messages back to update the size and height of the iframe based on notice size
 *
 * @component
 * @example
 * return (
 *   <NoticePreviewIframe />
 * )
 */
export const NoticePreviewIframe = (): JSX.Element => {
  const IFrameRef = useRef(null);
  const [selectedPreviewColor, setSelectedPreviewColor] = useState<PreviewColor>('gray');
  const [selectedSection, setSelectedSection] = useState<SDKPreviewSection>('notice');
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [iframeLoadedOneTime, setIframeLoadedOneTime] = useState(false);
  const { pushEventToDataLayer } = useGTM();
  const ASSETS_URL = useSPAAssetsUrl('@didomi-spa/cmp-preview');

  const onMessageReceivedFromIframe = React.useCallback(
    event => {
      try {
        const eventDetails = JSON.parse(event.data);
        if (eventDetails.action === 'size-change') {
          IFrameRef.current.style.height = eventDetails.height + 2 + 'px';
          // IFrameRef.current.style.maxWidth = eventDetails.width + 2 + 'px';
        }
        if (eventDetails.action === 'screen-change' && selectedSection !== eventDetails.screen) {
          setSelectedSection(eventDetails.screen);
        }
      } catch (e) /* eslint-disable no-empty */ {}
    },
    [selectedSection],
  );

  useEffect(() => {
    window.addEventListener('message', onMessageReceivedFromIframe);
    return () => window.removeEventListener('message', onMessageReceivedFromIframe);
  }, [onMessageReceivedFromIframe]);

  /* istanbul ignore next */
  const processIframeLoad = () => {
    if (!iframeLoadedOneTime) {
      // Set default values if the iframe loaded for the first time
      IFrameRef.current.contentWindow.postMessage({ action: 'update-color', payload: 'gray' }, ASSETS_URL);
      IFrameRef.current.contentWindow.postMessage({ action: 'update-section', payload: 'notice' }, ASSETS_URL);
    }
    setIframeLoaded(true);
    setIframeLoadedOneTime(true);
  };

  const updateColorSelection = (color: PreviewColor) => {
    pushEventToDataLayer('self-service-preview-notice-colors', { product: 'self-service', selection_value: color });
    setSelectedPreviewColor(color);
    setIframeLoaded(false);
    IFrameRef.current.contentWindow.postMessage({ action: 'update-color', payload: color }, ASSETS_URL);
  };

  const updateSdkSection = (section: SDKPreviewSection) => {
    if (selectedSection !== section) {
      pushEventToDataLayer('self-service-preview-notice-tabs', { product: 'self-service', selection_value: section });
      setSelectedSection(section);
      IFrameRef.current.contentWindow.postMessage({ action: 'update-section', payload: section }, ASSETS_URL);
    }
  };

  return (
    <DidomiSkeleton
      className={tw('w-full flex-col 2xl:self-baseline!', {
        '2xl:max-h-[480px]!': !iframeLoaded,
      })}
    >
      <div className="hidden md:block!">
        <DidomiTabHeading variant="flow">
          <DidomiTabHeaders className="flex justify-center mb-s mr-l 2xl:mt-xxl!">
            <DidomiTabHeader
              data-tracking="self-service-preview-notice-tabs"
              data-testid="header-notice"
              active={selectedSection === 'notice'}
              onHeaderClicked={() => updateSdkSection('notice')}
              name="1. Consent popin"
            />
            <DidomiTabHeader
              data-tracking="self-service-preview-notice-tabs"
              data-testid="header-purposes"
              active={selectedSection === 'purposes'}
              onHeaderClicked={() => updateSdkSection('purposes')}
              name="2. Purposes"
            />
            <DidomiTabHeader
              data-tracking="self-service-preview-notice-tabs"
              data-testid="header-vendors"
              active={selectedSection === 'vendors'}
              onHeaderClicked={() => updateSdkSection('vendors')}
              name="3. Partners"
            />
          </DidomiTabHeaders>
        </DidomiTabHeading>
      </div>
      <div className="flex bg-pimary-blue-6 flex-col-reverse items-center lg:(items-start! flex-row w-full!)">
        <div className="h-full flex justify-center mb-s w-full md:max-w-[80%]! lg:(max-w-none! min-w-[672px]!)" data-skeleton>
          <iframe
            ref={IFrameRef}
            onLoad={processIframeLoad}
            id="iframe-preview"
            data-testid="iframe-preview"
            className="w-full max-w-[624px] shadow-report-score rounded-2xl"
            title="Notice preview"
            src={`${ASSETS_URL}/assets/notice-preview/preview.html`}
            frameBorder="0"
            height="448px" // Default for the skeleton, will be updated when the iframe is loaded
            width="80%" // Default for the skeleton, will be updated when the iframe is loaded
          />
        </div>
        <PreviewColorPicker className="mb-xs lg:(ml-xs! mb-0!)" selectedColor={selectedPreviewColor} onColorChanged={updateColorSelection} />
      </div>
    </DidomiSkeleton>
  );
};
