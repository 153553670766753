import { useAuthToken } from '@didomi/utility-react';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';
import { useOrganizationProperty, useComplianceReportStatus } from '@hooks';
import { Report } from '@types';

type useComplianceReportProps = {
  enabled?: boolean;
};

/**
 * Fetches the compliance report for a particular property id and set of dates that constrain the search
 * We can safely assume a self-service organization we'll only have one property id at this point
 * We periodically fetch the report until we receive a result.
 * The endpoint will error when period_start === period_end. So we make sure to only run
 * this query when scraper.status === 'success', because that requires an update to the scraper entity
 *
 * Note: the compliance report depends on the results of the compliance report status and the organization property
 * Both are handled here for you but take into account that the loading of those will impact the loading for the report
 * */
export const useComplianceReport = ({ enabled }: useComplianceReportProps = { enabled: true }) => {
  const { data: organizationProperty } = useOrganizationProperty({ enabled });
  const { data: scraperResult } = useComplianceReportStatus({ enabled });
  const token = useAuthToken();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchComplianceReport = async () => {
    try {
      const response = await axiosWithInterceptors.get<{ data: Report[] }>(
        /* istanbul ignore next */
        `${CONFIG.environmentConfig.apiBaseUrl}reports?type=full&property_id=${organizationProperty?.id}&period_start=${scraperResult?.created_at}&period_end=${scraperResult?.updated_at}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const paginatedResponse = response.data;
      /* istanbul ignore next */
      return paginatedResponse?.data?.length ? paginatedResponse.data[0] : null;
    } catch (error) {
      throw new Error(error.message);
    }
  };
  const results = useQuery<Report, AxiosError>([organizationProperty?.id, 'report'], fetchComplianceReport, {
    enabled: enabled && !!organizationProperty?.id && scraperResult?.status === 'success',
  });

  return {
    ...results,
    isLoading: results.isLoading || results.isIdle,
  };
};
