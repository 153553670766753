import { useAuthToken } from '@didomi/utility-react';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { useOrganizationProperty } from '@hooks';
import { ScrapingSession, ScrapingSessionStatusResult } from '../types/scrapingSession';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

type useComplianceReportStatusProps = {
  enabled?: boolean;
};

/**
 * Fetches the scraping session for the organization
 * If no scraping session is found we can assume the compliance report has not finished running yet
 * Once we get a first scraping session we can stop polling the server since we can assume a report is there
 *
 * Note: the compliance report depends on the results of the organization property
 * The org property is handled here for you but take into account that the loading of it will impact the loading for the report status
 * */
export const useComplianceReportStatus = ({ enabled }: useComplianceReportStatusProps = { enabled: true }) => {
  const { data: organizationProperty, isLoading: loadingOrganizationProperty } = useOrganizationProperty();
  const token = useAuthToken();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchScrapingSessions = async () => {
    const response = await axiosWithInterceptors.get<{ data: ScrapingSession[] }>(
      // get the latest scraping session by sorting on descending creation date
      `${CONFIG.environmentConfig.apiBaseUrl}scraping-sessions?property_id=${/* istanbul ignore next */ organizationProperty?.id}&$sort[created_at]=-1`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    const paginatedResponse = response.data;

    /* istanbul ignore next */
    if (paginatedResponse?.data?.length) {
      return paginatedResponse.data[0];
    }

    return null;
  };

  const result = useQuery<ScrapingSessionStatusResult, AxiosError<{ message: string }>>([organizationProperty?.id, 'report', 'status'], fetchScrapingSessions, {
    enabled: enabled && !!organizationProperty?.id && !loadingOrganizationProperty,
  });

  return {
    ...result,
    isLoading: result.isLoading || loadingOrganizationProperty,
  };
};
