import { createServer, Model, Response } from 'miragejs';
import {
  createLowScoreOrganization,
  createMediumScoreOrganization,
  createOrganizationWithFailedScrapperResults,
  createOrganizationWithSuccessScrapperResultsButNoReport,
  createVeryLowScoreOrganization,
} from './factories';
import { OrganizationProperty } from '../types/organizationProperty';
import { ScrapingSession } from '../types/scrapingSession';
import { Report } from '../types/report';
import { ScrapingSessionsAcknowledgement } from 'types/scrapingSessionsAcks';

type ServerProps = {
  environment?: 'development' | 'test';
  scenario?: 'failure' | 'score-very-low' | 'score-low' | 'score-medium' | 'score-high' | 'success-scrap-no-report';
};

export function makeServer({ environment, scenario }: ServerProps = { environment: 'development', scenario: 'score-low' }) {
  return createServer({
    environment,

    models: {
      organization: Model,
      property: Model.extend<Partial<OrganizationProperty>>({}),
      scrapingSession: Model.extend<Partial<ScrapingSession>>({}),
      scrapingSessionAck: Model.extend<Partial<ScrapingSessionsAcknowledgement>>({}),
      report: Model.extend<Partial<Report>>({}),
    },

    routes() {
      this.urlPrefix = CONFIG.environmentConfig.apiBaseUrl;

      this.get('/premium-features', (_, args) => ({
        data: [
          {
            batch_exports: false,
            batch_exports_manual_run: false,
            consents_api: false,
            created_at: '2022-01-26T00:52:29.396Z',
            cross_device: false,
            data_residency_russia: false,
            id: '4qJnbw3i',
            integrations: false,
            organization_id: args.queryParams.organization_id,
            preference_center: false,
            preference_management_platform: false,
            store_cmp_consents: false,
            store_guest_cmp_consents: false,
            updated_at: '2022-01-26T00:52:29.396Z',
            versions_and_proofs: false,
          },
        ],
        limit: 100,
        skip: 0,
        total: 1,
      }));

      this.get('/scraping-sessions-acks', (schema, args) => {
        //@ts-ignore
        const acks = schema.where('scrapingSessionAck', { scraping_session_id: args?.queryParams?.scraping_session_id })?.models;

        return {
          data: acks,
          total: acks.length,
          limit: args?.params?.limit || 100,
          skip: args?.params?.skip || 0,
        };
      });

      this.post('/scraping-sessions-acks', (schema, args) => {
        const { scraping_session_id, organization_id } = JSON.parse(args.requestBody);
        const res = {
          created_at: '2022-01-28T22:37:11.122Z',
          updated_at: '2022-01-28T22:37:11.122Z',
          version: 0,
          scraping_session_id,
          organization_id,
          user_id: 'user123',
        };

        //@ts-ignore
        schema.create('scrapingSessionAck', res);
        return res;
      });

      this.get('/organizations', (schema, args) => {
        const organizations = schema.all('organization').models;
        return {
          data: organizations,
          total: organizations.length,
          limit: args?.params?.limit || 100,
          skip: args?.params?.skip || 0,
        };
      });

      this.get('/properties', (schema, args) => {
        //@ts-ignore
        const properties = schema.where('property', { organization_id: args?.queryParams?.organization_id })?.models;

        return {
          data: properties,
          total: properties.length,
          limit: args?.params?.limit || 100,
          skip: args?.params?.skip || 0,
        };
      });

      this.get('/scraping-sessions', (schema, args) => {
        //@ts-ignore
        const scrapingSessions = schema.where('scrapingSession', { property_id: args?.queryParams?.property_id })?.models;

        // @ts-ignore
        const sortedSessions = scrapingSessions.sort((a, b) => (a.created_at > b.created_at ? -1 : a.created_at < b.created_at ? 1 : 0));
        return {
          data: sortedSessions,
          total: sortedSessions.length,
          limit: args?.params?.limit || 100,
          skip: args?.params?.skip || 0,
        };
      });

      this.post('/scraping-sessions', (schema, args) => {
        const body = JSON.parse(args.requestBody);
        //@ts-ignore
        const existingSession = schema.scrapingSessions.findBy({ property_id: body?.property_id })?.attrs;

        const newSession = {
          //@ts-ignore
          organization_id: existingSession.organization_id,
          pages: null,
          //@ts-ignore
          property_id: existingSession.property_id,
          scraper: null,
          //@ts-ignore
          start_url: existingSession.start_url,
          status: 'ready',
          status_info: null,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        };

        //@ts-ignore
        schema.scrapingSessions.create(newSession);
        return newSession;
      });

      this.get('/reports', (schema, args) => {
        //@ts-ignore
        const reports = schema.where('report', report => report.property.id === args?.queryParams?.property_id)?.models;

        return {
          data: reports,
          total: reports.length,
          limit: args?.params?.limit || 100,
          skip: args?.params?.skip || 0,
        };
      });

      this.get('/shared-reports', (schema, args) => {
        //@ts-ignore
        const reports = schema.where('report', report => report.property.id === args?.queryParams?.property_id)?.models;

        return {
          data: reports,
          total: reports.length,
          limit: args?.params?.limit || 100,
          skip: args?.params?.skip || 0,
        };
      });

      this.passthrough('https://api-staging.didomi.io/*');
      this.passthrough('https://didomi-staging.eu.auth0.com/*');
      this.passthrough('https://uilib.didomi.io/*');
      this.passthrough('https://*--uilib-didomi.netlify.app/*');
      this.passthrough('https://api.privacy-center.org/v1/*');
      this.passthrough('https://api.hubspot.com/*');
      this.passthrough(request => {
        const uiLibBranchPattern = new RegExp(/https:\/\/(.*?)--uilib-didomi.netlify.app\/(.*?)/);
        if (uiLibBranchPattern.test(request.url)) return true;
      });
    },

    seeds(server) {
      switch (scenario) {
        case 'failure':
          createOrganizationWithFailedScrapperResults(server);
          break;
        case 'success-scrap-no-report':
          createOrganizationWithSuccessScrapperResultsButNoReport(server);
          break;
        case 'score-very-low':
          createVeryLowScoreOrganization(server);
          break;
        case 'score-medium':
          createMediumScoreOrganization(server);
          break;
        case 'score-low':
        default:
          createLowScoreOrganization(server);
      }
    },
  });
}

export function setupCypress() {
  let methods = ['get', 'put', 'patch', 'post', 'delete'];

  createServer({
    environment: 'test',
    routes() {
      for (const method of methods) {
        this[method](`${CONFIG.environmentConfig.apiBaseUrl}*`, async (schema, request) => {
          //@ts-ignore
          let [status, headers, body] = await window.handleFromCypress(request);
          return new Response(status, headers, body);
        });
      }

      this.passthrough('https://didomi-staging.eu.auth0.com/*');
      this.passthrough('https://uilib.didomi.io/*');
      this.passthrough('https://*--uilib-didomi.netlify.app/*');
      this.passthrough('https://api.privacy-center.org/v1/*');
      this.passthrough(request => {
        const uiLibBranchPattern = new RegExp(/https:\/\/(.*?)--uilib-didomi.netlify.app\/(.*?)/);
        if (uiLibBranchPattern.test(request.url)) return true;
      });
    },
  });
}
