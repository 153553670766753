import React, { useEffect, useState } from 'react';
import { DidomiBackButtonLink, DidomiButton, DidomiIconButton } from '@didomi/ui-atoms-react';
import { useUserProfile, useSPARouter, useGTM } from '@didomi/utility-react';
import { useMediaQuery, useSPAAssetsUrl } from '@didomi/helpers-react';
import { NoticePreviewIframe, PromoteBookDemo } from '@components';
import { useNavigate } from 'react-router-dom';

export const NoticePreviewPage = () => {
  const [showNoticeOnMobile, setShowNoticeOnMobile] = useState(false);
  const navigate = useNavigate();
  const { navigateTo } = useSPARouter();
  const { pushEventToDataLayer } = useGTM();
  const [userProfile, loadingUserProfile] = useUserProfile();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const isLargeScreen = useMediaQuery('(min-width: 1536px)');
  const ASSETS_URL = useSPAAssetsUrl('@didomi-spa/cmp-preview');

  const showNotice = !isMobile || (isMobile && showNoticeOnMobile);
  const showText = !isMobile || (isMobile && !showNoticeOnMobile);

  useEffect(() => {
    /* istanbul ignore if */
    if (!pushEventToDataLayer) return;

    if (userProfile?.self_register) {
      // TODO: Move this logic to the GTM utility and a react version
      pushEventToDataLayer('product-version', {
        product: 'self-service',
        page_path: 'notice-preview',
      });
    }
  }, [userProfile, pushEventToDataLayer]);

  return (
    <>
      <div className="h-full flex flex-col w-full overflow-auto items-center relative text-primary-blue-6 bg-cover bg-notice-preview p-s">
        <DidomiBackButtonLink className="self-baseline" text="Back to dashboard" onClick={() => navigateTo('/dashboard')} />
        <div className="w-full mt-s lg:mt-xxs! 2xl:mt-l! flex flex-col 2xl:flex-row! items-center text-center max-w-[740px] 2xl:max-w-[1382px]! mb-s">
          {showText && (
            <div className="flex flex-col 2xl:(mr-xxxl! text-left! sticky! top-[140px]! self-start!)">
              <h1 className="h1-catchline mt-xs md:mt-xxl! 2xl:mt-0!">Ensure your compliance with a consent notice!</h1>
              {isMobile && <img width="1020" height="960" className="my-xs self-center mt-s mb-xxs" src={`${ASSETS_URL}/assets/illustrations/notice-mobile-preview.png`} alt="" />}
              <div className="text-body-normal mt-xxs mb-xxl 2xl:mb-s!">
                The Didomi consent notice is a generic form composed of 3 layers, allowing you to manage the privacy of your users. Update content, add vendors, define purposes...
                You can customize your notice with your own colors and brand logo, and even include your own CSS code.
              </div>
              {isLargeScreen && <PromoteBookDemo className="flex!" onActionClicked={() => navigate('/book-demo')} disabled={loadingUserProfile} />}
            </div>
          )}
          {!showNotice && <DidomiButton onClick={() => setShowNoticeOnMobile(true)}>See a Didomi Consent Notice</DidomiButton>}
          {showNotice && (
            <div className="relative w-full 2xl:self-baseline! mb-[180px] md:mb-0!">
              {isMobile && (
                <DidomiIconButton className="absolute left-[10%] top-0" variant="rounded-border" onClick={() => setShowNoticeOnMobile(false)} icon="back" title="Back" />
              )}
              <NoticePreviewIframe />
            </div>
          )}
        </div>
      </div>
      {showNotice && !isLargeScreen && <PromoteBookDemo float isSmallLayout={isMobile} onActionClicked={() => navigate('/book-demo')} disabled={loadingUserProfile} />}
    </>
  );
};
