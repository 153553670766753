import React from 'react';
import { connect, theme } from '@didomi/ui-foundation';
import { content } from '@twind/content';
import { UtilityProvider } from '@didomi/utility-react';
import * as utility from '@didomi/utility';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ErrorBoundary } from '@didomi/helpers-react';
import { setupCypress } from '@mocks';
import { App } from '@pages';

const ASSET_URL = CONFIG.standalone ? 'http://localhost:9000' : new URL(DIDOMI_SPA_IMPORTS_MAP['@didomi-spa/cmp-preview']).origin;

// Setup Twind for cmp-preview only
connect('.cmp-preview-root', {
  plugins: { content },
  theme: {
    extend: {
      ...theme.extend,
      colors: {
        ...theme.extend.colors,
        preview: {
          gray: '#526E7A',
          violet: '#5B4D92',
          mustard: '#B99D53',
          salmon: '#E58C71',
        },
      },
      backgroundImage: {
        ...theme.extend.backgroundImage,
        'notice-preview': `url(${ASSET_URL}/assets/illustrations/background-notice.svg)`,
      },
      boxShadow: {
        ...theme.extend.boxShadow,
        'hubspot-box': '0 20px 40px rgb(221 232 237 / 50%)',
      },
      content: {
        check: '"✅"',
      },
    },
  },
});

if (window['Cypress']) {
  setupCypress();
} else {
  // makeServer({ scenario: 'failure' });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
    },
  },
});

const Root = () => {
  return (
    <UtilityProvider utility={utility}>
      <ErrorBoundary
        sentryConfig={{
          sentryDsn: CONFIG.sentryDsn,
          environment: CONFIG.environment,
          release: CONFIG.commitSha,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools />
          <App />
        </QueryClientProvider>
      </ErrorBoundary>
    </UtilityProvider>
  );
};

export { Root };
